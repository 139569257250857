import { Marker } from "@react-google-maps/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import React from "react"

interface LatLng {
  lat: number
  lng: number
}

const MapMarker = (props: { coords: LatLng; isDestination?: boolean }) => {
  if (props.isDestination === undefined) {
    return (
      <Marker
        onLoad={marker => {
          const customIcon = opts =>
            Object.assign(
              {
                path: faMapMarkerAlt.icon[4],
                scale: 0.075,
                fillColor: "#34495e",
                fillOpacity: 1,
                strokeColor: "#000",
                strokeWeight: 1,
              },
              opts
            )

          marker.setIcon(
            customIcon({
              fillColor: "green",
              strokeColor: "white",
            })
          )
        }}
        position={props.coords}
      />
    )
  }

  return (
    <Marker
      onLoad={marker => {
        const customIcon = opts =>
          Object.assign(
            {
              path: faMapMarkerAlt.icon[4],
              scale: 0.075,
              fillColor: "#34495e",
              fillOpacity: 1,
              strokeColor: "#000",
              strokeWeight: 1,
            },
            opts
          )

        marker.setIcon(
          customIcon({
            fillColor: "red",
            strokeColor: "black",
          })
        )
      }}
      position={props.coords}
    />
  )
}

export default MapMarker
