import { lineString } from "@turf/helpers"
import { getCoords } from "@turf/invariant"
import simplify from "@turf/simplify"
import lineOffset from "@turf/line-offset"
import { decode } from "@googlemaps/polyline-codec"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GatsbyLink from "gatsby-link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import {
  Circle,
  GoogleMap,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api"
import MapMarker from "../components/Map/Marker"

const JobRiderPage = () => {
  const thessalonikiCoords = {
    lat: 40.6001,
    lng: 22.9444,
  }

  const euosmosCoords = {
    lat: 40.6951,
    lng: 22.9344,
  }

  const thermiCoords = {
    lat: 40.5486,
    lng: 22.9774,
  }

  const polylineEncoded =
    "oknvFmogkCCG[{A`C_Ad@SB[@Y?s@UaCB]?KEIMCKJ_@YUDOFK?AEKOE_@G_@KFy@\\}HbDcGfCwL`F}B~@aBv@}FzCwOjIiDbBk@^mAh@aA`@eBp@gBf@wGpAqLrB{BZuB`@eGdAiB\\uA\\sAh@a@R{@r@_Ah@aAf@iAl@cGzCyL~EgFrBsBx@iBx@gCbAm@Qa@AyAsCm@aAqAmB_BaBqAuAi@q@}@{AmA_CqBaE{BeEcCiDmCsDuC{DmDoF{@{A_BcCqCgEaDaFkBoCk@_Ak@{@Ya@a@u@sAsBuEgH{A}BuGcK_IuL_DkEsB_CiBmBcB}AiB{AcAu@{B{AaCuAwDmBgGyB{EwAoF}AwBk@gB]yAWeBUeGy@iFq@aPoBsDa@gAEcB@cAHg@J}Ad@aA^y@h@}AxA{@rAo@nAu@zBQh@[xAMr@U~AQ~AOdBQjCIlCEpD@lCArFKbCKdB]zCo@rDm@bCw@zB_ApBgAhBkA`BgBrBiAdAiAr@_@ToAh@cA\\wBf@kB^kARMDaHnAkBb@gAZwAb@mAf@cBx@iBdAeAp@sA~@aAz@uCpCsDpDkAhAwAnA}EzEuDxDeA`AkC|BiBnAuDvBs@ZmAj@iF|BgEfBwMjFkGdCyAh@cAZsAZiB\\oEh@{EZaFd@uBZqB^kDhA_LnDgHzBoFxAmA\\mAT{ALaDT{ABgA?kBCiBMkBS}AYsBc@qEmAsPmEoAOoB?gADuAPmA\\iAf@uAz@mAdAiArA{GlKkA~AmArAaBvAy@j@yA|@_Bv@mA`@kBb@eJjByIlBuE~@}EdAcDn@qFvAWEUBiB`@}AV{@Lm@RiAp@g@f@i@t@]p@YdAUpAQrBe@fH]xEShCCr@i@xHUlDIzAQZ?DMhAO`Cm@|IC~@MTKHODMAKE]YUMe@a@UxAMt@@h@J\\p@lAd@z@J^TrAVvBBn@r@b@RJ"

  const decodedPolyline = decode(polylineEncoded)

  const decodedPolylineWithLatLng = React.useMemo(() => {
    return decodedPolyline.map(coords => {
      return {
        lat: coords[0],
        lng: coords[1],
      }
    })
  }, [decodedPolyline])

  const containerStyle = {
    marginTop: "20px",
    width: "100%",
    height: "75vh",
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCbt1BGWhFvAdjVToCfqaVz2wHCkw4nVRM",
  })

  const [map, setMap] = React.useState(null)

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [currentStep, _setCurrentStep] = React.useState(1)
  const setCurrentStep = stepValue => {
    if (stepValue < 1 || stepValue > 5) return

    _setCurrentStep(stepValue)
  }

  const descriptionMap = {
    1: "Firstly, a new Driver's Ride is being inputted by the User where we get Starting point and Destination.",
    2: "Then we use Google Directions API to get the route and the distance between the two points.",
    3: "Next step is to create Polygon around the route by taking two offset lines at 500meters distance from the route.",
    4: "Furthermore, we take two Radius around the Starting and Destination points to get another area where we will search for the potential matches.",
    5: "Finally, combining the Polygon and the two Radius we have the complete area where we will search for the potential matches.",
  }

  const descriptionMobileMap = {
    1: "Starting point and Destination from User",
    2: "Google Directions API",
    3: "Polygon around the route",
    4: "Radius around the points",
    5: "Combine Polygon and Radius",
  }

  const [offSetLine1, setOffSetLine1] = React.useState(null)
  const [offSetLine2, setOffSetLine2] = React.useState(null)

  React.useEffect(() => {
    createDriverArea(polylineEncoded)
  }, [])

  const createDriverArea = overviewPolyline => {
    const routePoints = decode(overviewPolyline)

    const routeLine = lineString(routePoints)

    const simpleLine = simplify(routeLine, {
      tolerance: 0.005,
      highQuality: true,
    })

    const offsetLine = lineOffset(simpleLine, 0.5)
    const offsetLineCoords = getCoords(offsetLine)

    // get LatLng array from above
    const latLngArray = offsetLineCoords.map(coords => {
      return {
        lat: coords[0],
        lng: coords[1],
      }
    })
    setOffSetLine1(latLngArray)

    const offsetLine2 = lineOffset(simpleLine, -0.5)
    const offsetLineCoords2 = getCoords(offsetLine2)

    // get LatLng array from above
    const latLngArray2 = offsetLineCoords2.map(coords => {
      return {
        lat: coords[0],
        lng: coords[1],
      }
    })
    setOffSetLine2(latLngArray2)
  }

  return (
    <Layout>
      <SEO title="Job Rider" />

      <div className="h-full w-full bg-slate-900">
        <GatsbyLink
          className="text-white font-semibold text-lg absolute left-8 top-8"
          to="/"
        >
          <FontAwesomeIcon color="white" icon={faArrowLeft} size="1x" />

          <span className="ml-2">Go back</span>
        </GatsbyLink>

        <div className="pt-24 pb-4 text-white">
          <h2 className="text-center  text-2xl">Introducing Job Rider</h2>

          <div className="px-4 sm:px-48 mt-8 text-justify">
            <p>
              <span className="text-teal-400 font-semibold">Job Rider:</span>{" "}
              the ingenious remedy to the daily commuter blues! Inspired by the
              plight of Greece's beloved teachers – seriously, they deserve
              medals for surviving those traffic jams and long driving
              distances. Proudly showcased on my personal portfolio, Job Rider
              isn't just about coding prowess; it's about using tech to sprinkle
              a little joy on our daily grind. So, grab your popcorn (or car
              keys) and dive into the{" "}
              <span className="text-teal-400 font-semibold">
                Loom video below
              </span>{" "}
              for a behind-the-scenes peek.
            </p>

            <br />

            <p className="text-teal-400 font-semibold">
              Because who knew saving teachers from traffic could be this much
              fun?
            </p>
          </div>

          <div className="h-128 w-64 mx-auto mt-16">
            <div
              style={{
                position: "relative",
                paddingBottom: "216.66666666666666%",
                height: "0",
              }}
            >
              <iframe
                src="https://www.loom.com/embed/adbcb7b8e20f4e829bffc7fc2d66e300?sid=926c9e12-24ef-46b4-8691-c7600a1ac274"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </div>
          </div>

          <div className="px-4 sm:px-48 mt-16 text-justify">
            <h5 className="text-center text-teal-400 font-semibold">
              Most exciting feature I created for Job Rider
            </h5>

            <h2 className="text-center mt-2">
              Matchmaking algorithm based on Users Rides
            </h2>

            <p className="mt-8">
              Besides the interesting step-by-step UI presented above there are
              more interesting features hidden. The crown jewel of Job Rider?
              Its matchmaking algorithm! Designed to pair commuters seamlessly,
              this feature is the heart and soul of the app, making carpooling a
              breeze.
            </p>

            <br />

            <p>
              Let's take a spin with a live demonstration – and what better
              guide than a lively map to showcase this in action!
            </p>

            <div className="mt-8 flex flex-col items-center gap-1">
              <div className="flex items-center gap-3">
                <button
                  className="mb-1 border border-teal-400 py-1 px-2 rounded-full"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  <FontAwesomeIcon color="white" icon={faArrowLeft} size="1x" />
                </button>

                <h4 className="font-semibold text-xl text-teal-400 pb-1">
                  Step {currentStep}
                </h4>

                <button
                  className="mb-1 border border-teal-400 py-1 px-2 rounded-full"
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  <FontAwesomeIcon
                    color="white"
                    icon={faArrowRight}
                    size="1x"
                  />
                </button>
              </div>

              <p className="w-2/3 hidden sm:block border border-teal-400 p-3 rounded-xl text-start 2xl:text-center">
                {descriptionMap[currentStep]}
              </p>

              <p className="px-2 w-full block sm:hidden border border-teal-400 p-3 rounded-xl text-center">
                {descriptionMobileMap[currentStep]}
              </p>
            </div>

            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={thessalonikiCoords}
                zoom={11}
                onUnmount={onUnmount}
              >
                <>
                  <MapMarker coords={euosmosCoords} />

                  <MapMarker coords={thermiCoords} isDestination={true} />
                </>

                {currentStep === 2 && (
                  <Polyline
                    options={{ strokeColor: "rgb(0 0 139)" }}
                    path={decodedPolylineWithLatLng}
                  />
                )}

                {(currentStep === 3 || currentStep === 5) && (
                  <>
                    <Polyline
                      options={{ strokeColor: "rgb(0 0 139)" }}
                      path={offSetLine1}
                    />

                    <Polyline
                      options={{ strokeColor: "rgb(0 0 139)" }}
                      path={offSetLine2}
                    />
                  </>
                )}

                {(currentStep === 4 || currentStep === 5) && (
                  <>
                    <Circle
                      options={{ strokeColor: "rgb(0 0 139)" }}
                      center={{
                        lat: euosmosCoords.lat - 0.01,
                        lng: euosmosCoords.lng + 0.01,
                      }}
                      radius={2000}
                    />

                    <Circle
                      options={{ strokeColor: "rgb(0 0 139)" }}
                      center={{
                        lat: thermiCoords.lat - 0.01,
                        lng: thermiCoords.lng + 0.01,
                      }}
                      radius={2000}
                    />
                  </>
                )}
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JobRiderPage
